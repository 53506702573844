// Import our custom CSS
import '../scss/theme.scss'

// import Swiper bundle with all modules installed
// import Swiper from 'swiper/bundle';
// import styles bundle
import 'swiper/css/bundle';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap';



jQuery(function ($) {

    $(window).scroll(function () {
        var scroll = $(window).scrollTop();

        //>=, not <=
        if (scroll >= 150) {
            //clearHeader, not clearheader - caps H
            $("body").addClass("is-scrolled");
        } else {
            $("body").removeClass("is-scrolled");
        }
    }); //missing );

}); // jQuery End


/*
 * NAVBAR - MOSTRA LA NAVBAR DURANTE LO SCROLL VERSO L'ALTO
 */
function navbarMoveToDownWhenScrollingUp(){
	
    document.addEventListener("DOMContentLoaded", function() {
        
        var staticLogo = document.querySelector('.logo--static');
        var scrolledLogo = document.querySelector('.logo--scrolled');
        var navbar = document.querySelector("header .navbar.js--navbarScrollingUp");

        if(navbar){

            var navbarHeight = navbar.offsetHeight;
            var prevScrollpos = window.pageYOffset;
            var scrolledDown = false;

            window.onscroll = function() {
                var currentScrollPos = window.pageYOffset;
                console.log(currentScrollPos);
                if (currentScrollPos > 200) {
                    scrolledDown = true;
                    document.body.classList.add("is-scrolled");
                } else {
                    scrolledDown = false;
                    document.body.classList.remove("is-scrolled");
                }

                if (scrolledDown) {
                    if (prevScrollpos > currentScrollPos) {
                        navbar.style.top = '0';
                    } else {
                        navbar.style.top = '-' + navbarHeight + 'px';
                    }
                }

                prevScrollpos = currentScrollPos;
            };
        }

        if (scrolledLogo) {
            window.addEventListener('scroll', function() {
                var scrollPosition = window.scrollY || window.pageYOffset;
        
            if (scrollPosition > 200) {
                gsap.to(staticLogo, { opacity: 0, duration: 0.35, display: "none" },0);
                gsap.to(scrolledLogo, { opacity: 1, duration: 0.35, display: "block" },0);
            } else if(scrollPosition <= 0) {
                gsap.to(staticLogo, { opacity: 1, duration: 0.35, display: "block" },0);
                gsap.to(scrolledLogo, { opacity: 0, duration: 0.35, display: "none" },0);
            }
            });
        };
        
    });

}
navbarMoveToDownWhenScrollingUp();


function smoothScroll() {
    // Seleziona tutti i link che iniziano con #
    const anchors = document.querySelectorAll('a[href^="#"]');

    anchors.forEach(anchor => {
        // Controlla se l'ancora non ha l'attributo data-bs-toggle="modal"
        if (!anchor.hasAttribute('data-bs-toggle')) {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();

                const targetElement = document.querySelector(this.getAttribute('href'));
                
                if (targetElement) {
                    targetElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start', // Allinea la parte superiore dell'elemento in cima alla viewport
                    });
                } else {
                    console.error('Elemento non trovato per:', this.getAttribute('href'));
                }
            });
        }
    });
}

smoothScroll();





function navbarBehaviour() {

    var navbar = document.querySelector("header .navbar.js--hideScroll");
    // Trovo l'altezza della navbar

    if (navbar) {
        var navbarHeight = navbar.offsetHeight;

        // Aggiungo l'altezza della navbar come padding all'elemento main
        var mainContent = document.querySelector('main.site-content');
        mainContent.style.paddingTop = navbarHeight + 'px';

        // Salvo la posizione dello scroll verticale
        var prevScrollpos = window.pageYOffset;
        var scrolledDown = false;

        // Evento scroll sull'oggetto window
        window.onscroll = function () {
            var currentScrollPos = window.pageYOffset;

            // Se l'user scrolla +200px si attiva l'effetto
            if (currentScrollPos > 200) {
                scrolledDown = true;
            } else {
                scrolledDown = false;
            }

            if (scrolledDown) {
                if (prevScrollpos > currentScrollPos) {
                    navbar.style.top = '0';
                } else {
                    navbar.style.top = '-' + navbarHeight + 'px';
                }
            }

            prevScrollpos = currentScrollPos;
        };

    }

}
// navbarBehaviour();


function gsapAnimation(){

    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.batch(".gs--fadeInFromUp", {
        start: "top 90%",
        onEnter: (elements, triggers) => {
            gsap.to(elements, {opacity: 1, stagger: 0.25, y: 0, duration: 1 });
        },
    });

    ScrollTrigger.batch(".gs--fadeInFromDown", {
        start: "top 90%",
        onEnter: (elements, triggers) => {
            gsap.to(elements, {opacity: 1, stagger: 0.25, y: 0, duration: 1 });
        },
    });


    ScrollTrigger.batch(".gs--fadeInFromRight", {
        start: "top 90%",
        onEnter: (elements, triggers) => {
            gsap.to(elements, {opacity: 1, stagger: 0.25, x: 0, duration: 1 });
        },
    });

    ScrollTrigger.batch(".gs--fadeInFromLeft", {
        start: "top 90%",
        onEnter: (elements, triggers) => {
            gsap.to(elements, {opacity: 1, stagger: 0.25, x: 0, duration: 1 });
        },
    });

    ScrollTrigger.batch(".gs--fadeInDiagonalFromLeft", {
        start: "top 90%",
        onEnter: (elements, triggers) => {
            gsap.to(elements, {opacity: 1, stagger: 0.25, x: 0, y: 0, duration: 1 });
        },
    });

    ScrollTrigger.batch(".gs--fadeInDiagonalFromRight", {
        start: "top 90%",
        onEnter: (elements, triggers) => {
            gsap.to(elements, {opacity: 1, stagger: 0.25, x: 0, y: 0, duration: 1 });
        },
    });
    

    ScrollTrigger.batch(".gs--maskUp", {
        start: "top 90%",
        onEnter: (elements, triggers) => {
            gsap.to(elements, {clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)", y: 0, duration: 1.5, scrub: true });
        },
    });

    ScrollTrigger.batch(".gs--fadeInToOpacity", {
        start: "top 70%",
        onEnter: (elements, triggers) => {
            gsap.to(elements, {opacity: 1, duration: 0.5, y: 0, scrub: true });
        },
    });

}
gsapAnimation();


function sliderTimeline(){

    var navTimeline = new Swiper(".swiper.swiper--timeline-custom-pagination", {
        speed: 500,
        spaceBetween: 64,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        // direction: "horizontal",
        slidesPerView: 5,
        // loop: false,
        centeredSlides: true,
        // initialSlide: 0,
        breakpoints: {
            0: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 5,
            }
        },
    });
    
    var sliderTimeline = new Swiper(".swiper.swiper--timeline", {
        speed: 700,
        spaceBetween: 64,
        // direction: "horizontal",
        slidesPerView: 1,
        thumbs: {
            swiper: navTimeline
        }
    });
    

    sliderTimeline.on('slideChangeTransitionStart', function() {
        navTimeline.slideTo(sliderTimeline.activeIndex);
    });
    
    navTimeline.on('transitionStart', function(){
        sliderTimeline.slideTo(navTimeline.activeIndex);
    });


}sliderTimeline();





/*
function sliderServizi(){

    var navServizi = new Swiper(".swiper.slider--nav", {
        direction: "vertical",
        spaceBetween: 12,
        slidesPerView: "auto",
        mousewheel: true,
        watchOverflow: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        
        breakpoints: {
            0: {
                direction: "horizontal",
                autoHeight: "false",
            },
            768: {
                direction: "vertical",
                slidesPerView: "auto",
            }
        },
    });
    
    var sliderServizi = new Swiper(".swiper.slider--servizi", {
        direction: "vertical",
        slidesPerView: 1,
        spaceBetween: 128,
        mousewheel: {
            forceToAxis: true,
            sensitivity: 0.5,
            releaseOnEdges: true,
        },
        watchOverflow: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        preventInteractionOnTransition: false,
        thumbs: {
            swiper: navServizi
        },
        autoHeight: true,
        freeMode: true,
        breakpoints: {
            0: {
                spaceBetween: 32,
            },
            768: {
                spaceBetween: 128,
            }
        },
    });
    

    sliderServizi.on('slideChangeTransitionStart', function() {
        navServizi.slideTo(sliderServizi.activeIndex);
    });
    
    navServizi.on('transitionStart', function(){
        sliderServizi.slideTo(navServizi.activeIndex);
    });


}sliderServizi();
*/





function sezioneServizi(){
    
    gsap.registerPlugin(ScrollTrigger);
    
    const showcaseServices = document.querySelectorAll(".showcase-services");
    const servicesHighlights = document.querySelectorAll(".services-highlights");
    const items = document.querySelectorAll(".item-services");
    const titles = document.querySelectorAll(".highlights-title");

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: showcaseServices,
            start: "top top",
            end: "bottom bottom",
            pin: servicesHighlights,
            pinSpacing: false,
        }
    });


    if (titles.length > 0) {
        titles[0].classList.add("active");
    }


    const mm = gsap.matchMedia();
    let startTriggerPoint = "top 50%";
    let endTriggerPoint = "bottom 50%";

    mm.add("(max-width: 768px)", () => {
        startTriggerPoint = "top 25%";
        endTriggerPoint = "bottom 25%";
    });

    items.forEach((item, index) => {
        ScrollTrigger.create({
            trigger: item,
            start: startTriggerPoint, 
            end: endTriggerPoint, 
            onEnter: () => activateTitle(index),
            onEnterBack: () => activateTitle(index), 
            onLeave: () => deactivateTitle(index), 
            onLeaveBack: () => deactivateTitle(index) 
        });
    });

    // Funzione per attivare e disattivare la classe active
    function activateTitle(index) {
        titles.forEach((title) => title.classList.remove("active"));
        if (titles[index]) titles[index].classList.add("active");
    }

    function deactivateTitle(index) {
        if (titles[index]) titles[index].classList.remove("active");
    }
}sezioneServizi();